export default theme => ({
	root: {
		'& .MuiGrid-item': {
			border: '0px dashed',
			borderTopWidth: 1,
			marginTop: -1,
			borderColor: theme.palette.text.hint,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: 0,
			[theme.breakpoints.up('xs')]: {
				borderLeftWidth: 1,
				marginLeft: -1
			}
		}
	}
})